/* eslint no-debugger: "warn" */

// dependencies
import React from 'react';

import LocalStorageService from './LocalStorageService';

// const serverRoot = 'http://localhost:1337' // ?username=dom@appt.digital&password=password

// const serverRoot = 'https://dev-api-besmart.ontheappt.cloud'
//const serverRoot = 'https://stage-api-besmart.ontheappt.cloud'
const serverRoot = 'https://prod-api-besmart-nomad.ontheappt.cloud';

const apiToken = null;

class Api extends React.Component {
  login = async (username, password) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var options = {
      method: 'POST',
      headers,
    };

    const requestUrl = '/api/auth/login';

    options.body = JSON.stringify({
      username,
      password,
    });

    const fullUrl = `${serverRoot}${requestUrl}`;

    const request = new Request(fullUrl, options);
    const response = await fetch(request);

    return response.json();
  };

  // resetPasswordRequest = async (organisationId, email) => {
  //   const headers = new Headers()
  //   headers.append('Content-Type', 'application/json')
  //   var options = {
  //     method: 'POST',
  //     headers
  //   }
  verifyAccount = async (username, password, verificationCode) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var options = {
      method: 'POST',
      headers,
    };

    const requestUrl = '/api/auth/login';

    options.body = JSON.stringify({
      username,
      password,
      verificationCode,
    });

    const fullUrl = `${serverRoot}${requestUrl}`;

    const request = new Request(fullUrl, options);
    const response = await fetch(request);

    return response.json();
  };

  resetPassword = async (organisationId, email) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var options = {
      method: 'POST',
      headers,
    };

    const requestUrl = '/send-reset-link';
    let urlParams = `?email=${encodeURIComponent(email)}`;
    if (organisationId) {
      urlParams = `${urlParams}&organisationId=${encodeURIComponent(
        organisationId,
      )}`;
    }

    const fullUrl = `${serverRoot}${requestUrl}${urlParams}`;

    const request = new Request(fullUrl, options);
    const response = await fetch(request);

    return response.json();
  };

  // TODO: for now, you need to supply the apiToken
  // this is dumb and need to be refactored
  get = async (apiToken, url, params = {}) => {
    // check for an api key, otherwise throw an error

    const currentOrg = LocalStorageService.get('activeOrg');

    // use the apiToken from localstorage instead
    apiToken = LocalStorageService.get('apiToken');

    const options = {
      method: 'GET',
    };

    if (apiToken) {
      params.apiToken = apiToken;
    }

    if (!params.orgId && currentOrg) {
      params.orgId = currentOrg.id;
    }

    let paramsStr = '';
    if (params) {
      paramsStr = '';
      for (let key in params) {
        let value = params[key];
        console.log('key', key);
        console.log('value', value);
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        if (paramsStr === '') {
          paramsStr += '?';
        } else {
          paramsStr += '&';
        }
        paramsStr += `${key}=${value}`;
      }
    }
    console.log('ApiService');
    console.log(url);
    console.log(paramsStr);

    const request = new Request(`${serverRoot}${url}${paramsStr}`, options);
    const response = await fetch(request);
    return response.json();
  };

  // TODO: for now, you need to supply the apiToken
  // this is dumb and need to be refactored
  post = async (apiToken, url, params = {}, options = {}) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const currentOrg = LocalStorageService.get('activeOrg');

    // use the apiToken from localstorage instead
    apiToken = LocalStorageService.get('apiToken');

    var optionsParams = {
      method: 'POST',
      headers,
      body: JSON.stringify(options),
    };

    if (apiToken) {
      params.apiToken = apiToken;
    }

    if (currentOrg) {
      params.orgId = currentOrg.id;
    }

    let paramsStr = '';
    if (params) {
      paramsStr = '';
      for (let key in params) {
        let value = params[key];
        console.log('key', key);
        console.log('value', value);
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        if (paramsStr === '') {
          paramsStr += '?';
        } else {
          paramsStr += '&';
        }
        paramsStr += `${key}=${value}`;
      }
    }

    const request = new Request(
      `${serverRoot}${url}${paramsStr}`,
      optionsParams,
    );
    const response = await fetch(request);
    const returnResponse = await response.json();
    console.log('response ', returnResponse);
    return returnResponse;
  };

  // Before login no apiToken is available so use this instead
  // which is identical to Post apart from that
  postNoToken = async (url, params = {}, options = {}) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    var optionsParams = {
      method: 'POST',
      headers,
      body: JSON.stringify(options),
    };

    let paramsStr = '';
    if (params) {
      paramsStr = '';
      for (let key in params) {
        let value = params[key];
        console.log('key', key);
        console.log('value', value);
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        if (paramsStr === '') {
          paramsStr += '?';
        } else {
          paramsStr += '&';
        }
        paramsStr += `${key}=${value}`;
      }
    }

    const request = new Request(
      `${serverRoot}${url}${paramsStr}`,
      optionsParams,
    );
    const response = await fetch(request);
    const returnResponse = await response.json();
    console.log('response ', returnResponse);
    return returnResponse;
  };

  put = async (apiToken, url, params = {}, options = {}) => {
    // check for an api key, otherwise throw an error

    if (!apiToken) {
      throw Error('Missing API Token');
    }

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    var optionsParams = {
      method: 'PUT',
      headers,
      body: JSON.stringify(options),
    };

    params.apiToken = apiToken;

    if (!params.orgId) {
      const currentOrg = LocalStorageService.get('activeOrg');
      if (currentOrg) {
        params.orgId = currentOrg.id;
      }
    }

    let paramsStr = '';
    if (params) {
      paramsStr = '';
      for (let key in params) {
        let value = params[key];
        console.log('key', key);
        console.log('value', value);
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        if (paramsStr === '') {
          paramsStr += '?';
        } else {
          paramsStr += '&';
        }
        paramsStr += `${key}=${value}`;
      }
    }

    const request = new Request(
      `${serverRoot}${url}${paramsStr}`,
      optionsParams,
    );
    const response = await fetch(request);
    const returnResponse = await response.json();
    console.log('response ', returnResponse);
    return returnResponse;
  };

  // Before login no apiToken is available so use this instead
  // which is identical to Put apart from that
  putNoToken = async (url, params = {}, options = {}) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    var optionsParams = {
      method: 'PUT',
      headers,
      body: JSON.stringify(options),
    };

    let paramsStr = '';
    if (params) {
      paramsStr = '';
      for (let key in params) {
        let value = params[key];
        console.log('key', key);
        console.log('value', value);
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        if (paramsStr === '') {
          paramsStr += '?';
        } else {
          paramsStr += '&';
        }
        paramsStr += `${key}=${value}`;
      }
    }

    const request = new Request(
      `${serverRoot}${url}${paramsStr}`,
      optionsParams,
    );
    const response = await fetch(request);
    const returnResponse = await response.json();
    console.log('response ', returnResponse);
    return returnResponse;
  };

  // TODO: for now, you need to supply the apiToken
  // this is dumb and need to be refactored
  sendDelete = async (apiToken, url, params = {}) => {
    // check for an api key, otherwise throw an error

    if (!apiToken) {
      throw Error('Missing API Token');
    }

    const options = {
      method: 'DELETE',
    };

    params.apiToken = apiToken;

    if (!params.orgId) {
      const currentOrg = LocalStorageService.get('activeOrg');
      if (currentOrg) {
        params.orgId = currentOrg.id;
      }
    }

    let paramsStr = '';
    if (params) {
      paramsStr = '';
      for (let key in params) {
        let value = params[key];
        console.log('key', key);
        console.log('value', value);
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        if (paramsStr === '') {
          paramsStr += '?';
        } else {
          paramsStr += '&';
        }
        paramsStr += `${key}=${value}`;
      }
    }
    console.log('ApiService');
    console.log(url);
    console.log(paramsStr);

    const request = new Request(`${serverRoot}${url}${paramsStr}`, options);
    const response = await fetch(request);
    return response.json();
  };

  registerPublic = async data => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var options = {
      method: 'POST',
      headers,
    };

    const requestUrl = '/api/auth/signup';

    options.body = JSON.stringify({
      data,
    });

    const fullUrl = `${serverRoot}${requestUrl}`;

    const request = new Request(fullUrl, options);
    const response = await fetch(request);

    return response.json();
  };
}

// export default Api
export default new Api();
